@font-face {
    font-family: 'SourceSansPro-Black';
    src: url('../fonts/source-serif-pro/SourceSansPro-Black.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-BlackIt';
    src: url('../fonts/source-serif-pro/SourceSansPro-BlackIt.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('../fonts/source-serif-pro/SourceSansPro-Bold.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-BoldIt';
    src: url('../fonts/source-serif-pro/SourceSansPro-BoldIt.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-ExtraLight';
    src: url('../fonts/source-serif-pro/SourceSansPro-ExtraLight.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-ExtraLightIt';
    src: url('../fonts/source-serif-pro/SourceSansPro-ExtraLightIt.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-It';
    src: url('../fonts/source-serif-pro/SourceSansPro-It.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-Light';
    src: url('../fonts/source-serif-pro/SourceSansPro-Light.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-LightIt';
    src: url('../fonts/source-serif-pro/SourceSansPro-LightIt.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('../fonts/source-serif-pro/SourceSansPro-Regular.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url('../fonts/source-serif-pro/SourceSansPro-Semibold.otf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'SourceSansPro-SemiboldIt';
    src: url('../fonts/source-serif-pro/SourceSansPro-SemiboldIt.otf') format('opentype');
    font-display: swap;
  }
:root {
    --blue:#36b3a8;
    --indigo:#6610f2;
    --purple:#5856d6;
    --pink:#ff2d55;
    --red:#B33641;
    --orange:#E05F20;
    --yellow:#B36A36;
    --green:#36B36A;
    --teal:#3680B3;
    --cyan:#17a2b8;
    --white:#fff;
    --gray:#7F7F7F;
    --gray-dark:#555555;
    --primary:#36b3a8;
    --secondary:#7F7F7F;
    --success:#36B36A;
    --info:#3680B3;
    --warning:#B36A36;
    --danger:#B33641;
    --light:#FAFAFA;
    --dark:#1F1F1F;
    --black: #000000;

    --border-color1: #E2E2E2;
    --border-color2: #E8E8E8;
    --border-color3: #EDEDED;
    --border-color4: #ECECEC;

    --color-nav-item: #000000;
    --color-nav-item-active: #E05F20;
    --hero-text-color: #161616;
    --secondary-text-color: #161616;
    --sub-title-text-color: #141414;
    --paragraph-color: #161616;
    --footer-text-colot: #3E3E3E;
    /* home */
    --bgcolor-sandal: #F3EADE;
    --bgcolor-peach-blue : #F5FAF8;
    --bgcolor-peach-green : #F8F9F9;
    --bgcolor-light-gray : #FAFAFA;
    --bgcolor-lavender : #EDEBF6;
    /* export project */
    --bgcolor-peachdark-green : #F7FEEA;
    /* service */
    --bgcolor-light-sandle: #F8F2EB;
    --bgcolor-dark-sandle: #FEF2EB;
    --bgcolor-vilot-gradient-support: rgb(91,75,138);
    --bgcolor-vilot-gradient: linear-gradient(90deg, rgba(91,75,138,1) 0%, rgba(88,61,205,1) 84%, rgba(91,75,139,1) 100%);
    /* footer */
    --copyright-text-color: #3D3D3D;
    /* font config */
    --hero-text: 54px;
    --section-title: 34px;
    --section-title-description: 22px;
    /* font-family configuration */
    --font-regular: 'SourceSansPro-Regular';
    --font-semibold: 'SourceSansPro-SemiBold';
    --font-bold: 'SourceSansPro-Bold';

  }
  *,
  ::after,
  ::before {
    -webkit-box-sizing:border-box;
    box-sizing:border-box
  }
  html {
    line-height:1.15;
    -webkit-text-size-adjust:100%;
    -webkit-tap-highlight-color:transparent;
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display:block
  }
  body {
    margin:0;
    font-family: var(--font-regular);
    font-size:1.125rem;
    font-weight:400;
    line-height:1.45;
    color:#161616;
    text-align:left;
    background-color:#FFFFFF
  }
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline:0!important
  }
  hr {
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    height:0;
    overflow:visible
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration:underline;
    -webkit-text-decoration:underline dotted;
    text-decoration:underline dotted;
    cursor:help;
    border-bottom:0;
    -webkit-text-decoration-skip-ink:none;
    text-decoration-skip-ink:none
  }
  address {
    font-style:normal;
    line-height:inherit
  }
  dl,
  ol,
  ul {
    list-style-type: none;
    margin:0;
    padding: 0;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom:0
  }

b,
strong {
  font-weight:bolder
}
small {
  font-size:80%
}
sub,
sup {
  position:relative;
  font-size:75%;
  line-height:0;
  vertical-align:baseline
}
sub {
  bottom:-.25em
}
sup {
  top:-.5em
}
a {
  color:#000;
  text-decoration:none;
  background-color:transparent
}
a:hover {
  color:#000000;
  /* text-decoration:underline */
}
a:not([href]) {
  color:inherit;
  text-decoration:none
}
a:not([href]):hover {
  color:inherit;
  text-decoration:none
}

code,
kbd,
pre,
samp {
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size:1em
}
pre {
  margin-top:0;
  overflow:auto
}
figure {
  margin:0 0 1rem
}
img {
  vertical-align:middle;
  border-style:none
}
svg {
  overflow:hidden;
  vertical-align:middle
}
table {
  border-collapse:collapse
}
caption {
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#3E3E3E;
  text-align:left;
  caption-side:bottom
}
th {
  text-align:inherit
}
label {
  display:inline-block;
}
button {
  border-radius:0
}
button:focus {
  outline:1px dotted;
  outline:5px auto -webkit-focus-ring-color
}
button,
input,
optgroup,
select,
textarea {
  margin:0;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit
}
button,
input {
  overflow:visible
}
button,
select {
  text-transform:none
}
select {
  word-wrap:normal
}
[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance:button
}
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor:pointer
}
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding:0;
  border-style:none
}
input[type=checkbox],
input[type=radio] {
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:0
}
input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance:listbox
}
textarea {
  overflow:auto;
  resize:vertical
}
fieldset {
  min-width:0;
  padding:0;
  margin:0;
  border:0
}
legend {
  display:block;
  width:100%;
  max-width:100%;
  padding:0;
  font-size:1.5rem;
  line-height:inherit;
  color:inherit;
  white-space:normal
}
progress {
  vertical-align:baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height:auto
}
[type=search] {
  outline-offset:-2px;
  -webkit-appearance:none
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance:none
}
::-webkit-file-upload-button {
  font:inherit;
  -webkit-appearance:button
}
output {
  display:inline-block
}
summary {
  display:list-item;
  cursor:pointer
}
template {
  display:none
}
[hidden] {
  display:none!important
}
.h1,
h1 {
  font-size:4.73482rem;
  font-weight: normal;
}
.h2,
h2 {
  font-size:3.552rem;
  font-weight: normal;
}
.h3,
h3 {
  font-size:2.66467rem;
  font-weight: normal;
}
.h4,
h4 {
  font-size:1.999rem;
  font-weight: normal;
}
.h5,
h5 {
  font-size:1.49962rem;
  font-weight: normal;
}
.h6,
h6 {
  font-size:.84396rem;
  font-weight: normal;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
/* start 404 page css */
.page-notfound-section {
  padding: 60px 0 130px;
  text-align: center;
}
.page-notfound-image {
  width: 100%;
  max-width: 450px;
  margin-bottom: 40px;
}
.page-notfound-section h4 {
  font-size: 24px;
  font-family: var(--font-semibold);
  margin-bottom: 17px;
}
.page-notfound-section p {
  font-size: 18px;
  font-family: var(--font-regular);
}
/* end 404 page css */

/* start under Construction page css */
@media (max-width:667px) {
  .page-notfound-section {
    padding: 40px 0 80px;
  }
  .page-notfound-section p > br {
    display: none;
  }
}
/* end 404 page css */
@media (min-width:1250px) {
  .container {
    max-width:1230px
  }
}